:root {
  --brand-tonesprimary-voilaprimary40: #794fdb;
  --brand-tonesprimary-voilaprimary50: #8661df;
  --brand-tonesprimary-voilaprimary90: #d7caf4;
  --brand-tonesprimary-voilaprimary95: #e4dcf8;
  --brand-tonesprimary-voilaprimary99: #f9f7fd;
  --brand-tonessecondary-dawnsecondary90: #ac99b5;
  --brand-tonessecondary-dawnsecondary95: #d5ccda;
  --CTA-bold-font-family: "DMSans-Bold", Helvetica;
  --CTA-bold-font-size: 16px;
  --CTA-bold-font-style: normal;
  --CTA-bold-font-weight: 700;
  --CTA-bold-letter-spacing: -.3px;
  --CTA-bold-line-height: 24px;
  --light-00: 0px 5px 6px 1px #bdbdbd26, 0px 2px 4px 0px #bdbdbd33;
  --light-02: 0px 6px 6px 0px #d1d1d14d, 0px 9px 8px 3px #b8b8b826;
  --light-03: 0px 2px 3px 0px #b6b6b64d, 0px 6px 10px 4px #b6b6b626;
  --prm-12: #794fdb1f;
  --prm-16: #794fdb29;
  --prm-8: #794fdb14;
  --scd-16: #30205829;
  --ui-essentialsneutralntrl100: #fff;
  --ui-essentialsneutralntrl40: #6a686b;
  --ui-essentialsneutralntrl60: #9b9a9c;
  --ui-essentialsneutralntrl90: #e6e6e6;
  --ui-essentialsneutralntrl95: #f2f2f2;
}

.element {
  background-color: #fff;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  display: flex;
}

body {
  overflow-x: hidden;
}

.element .div {
  background-color: #fff;
  width: 1920px;
  height: 14736px;
  position: relative;
}

.element .image {
  width: 1920px;
  height: 5753px;
  position: absolute;
  top: 8100px;
  left: 0;
}

.element .overlap {
  width: 1920px;
  height: 966px;
  position: absolute;
  top: 4px;
  left: 0;
}

.element .hero {
  background-image: url("rectangle-95.694bdf38.png");
  background-size: 100% 100%;
  width: 1920px;
  height: 966px;
  position: absolute;
  top: 0;
  left: 0;
}

.element .group {
  width: 575px;
  height: 120px;
  position: relative;
  top: 517px;
  left: 120px;
}

.element .frame {
  align-items: flex-start;
  gap: 16px;
  display: inline-flex;
  position: absolute;
  top: 64px;
  left: 0;
}

.element .UIX {
  width: 209px;
  height: 56px;
  position: relative;
}

.element .frame-wrapper {
  background-color: #fff;
  border-radius: 28px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  padding: 4px 16px 4px 4px;
  display: inline-flex;
  position: relative;
}

.element .frame-2 {
  flex: none;
  align-items: center;
  gap: 12px;
  display: inline-flex;
  position: relative;
}

.element .overlap-group-wrapper {
  background-color: #2f2f2f;
  border-radius: 24px;
  width: 48px;
  height: 48px;
  position: relative;
}

.element .overlap-group {
  width: 22px;
  height: 22px;
  position: relative;
  top: 13px;
  left: 13px;
}

.element .line {
  width: 5px;
  height: 22px;
  position: absolute;
  top: 0;
  left: 9px;
}

.element .img {
  width: 22px;
  height: 4px;
  position: absolute;
  top: 9px;
  left: 0;
}

.element .text-wrapper {
  color: #191919;
  letter-spacing: .46px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Neue Haas Grotesk Display Pro-75Bold, Helvetica;
  font-size: 24px;
  font-weight: 700;
  line-height: 32.9px;
  position: relative;
}

.element .div-wrapper {
  width: 149px;
  height: 56px;
  position: relative;
}

.element .UIX-2 {
  width: 183px;
  height: 56px;
  position: relative;
}

.element .text-wrapper-2 {
  color: #dadada;
  letter-spacing: -.39px;
  white-space: nowrap;
  height: 40px;
  font-family: Neue Haas Grotesk Text Pro-75Bold, Helvetica;
  font-size: 24px;
  font-weight: 700;
  line-height: 40px;
  position: absolute;
  top: 0;
  left: 0;
}

.element .frame-3 {
  flex-direction: column;
  align-items: flex-end;
  gap: 24px;
  display: inline-flex;
  position: absolute;
  top: 116px;
  left: 1620px;
}

.element .android-IOS {
  color: #fff;
  letter-spacing: -.39px;
  text-align: right;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-family: Neue Haas Grotesk Text Pro-55Roman, Helvetica;
  font-size: 28px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.element .text-wrapper-3 {
  color: #fff;
  letter-spacing: -.39px;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Neue Haas Grotesk Text Pro-55Roman, Helvetica;
  font-size: 28px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.element .frame-4 {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 24px;
  display: inline-flex;
  position: absolute;
  top: 116px;
  left: 120px;
}

.element .text-wrapper-4 {
  color: #fff;
  letter-spacing: -.16px;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-family: Neue Haas Grotesk Text Pro-75Bold, Helvetica;
  font-size: 32px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.element .p {
  color: #fff;
  letter-spacing: -.4px;
  width: 565px;
  font-family: Neue Haas Grotesk Text Pro-55Roman, Helvetica;
  font-size: 80px;
  font-weight: 400;
  line-height: 96px;
  position: relative;
}

.element .the-challenge {
  background-color: #060308;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  width: 1920px;
  padding: 64px 0;
  display: flex;
  position: absolute;
  top: 970px;
  left: 0;
}

.element .frame-5 {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  gap: 48px;
  width: 100%;
  padding: 0 120px;
  display: flex;
  position: relative;
}

.element .frame-6 {
  flex: none;
  justify-content: space-between;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  display: flex;
  position: relative;
}

.element .frame-7 {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-items: flex-start;
  gap: 32px;
  display: inline-flex;
  position: relative;
}

.element .text-wrapper-5 {
  color: #fff;
  letter-spacing: -.17px;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-family: Neue Haas Grotesk Text Pro-75Bold, Helvetica;
  font-size: 32px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.element .finding-love-in-a {
  color: #fff;
  letter-spacing: -.24px;
  width: 1275px;
  margin-top: -1px;
  font-family: Neue Haas Grotesk Text Pro-Bold, Helvetica;
  font-size: 32px;
  font-weight: 400;
  line-height: 44.8px;
  position: relative;
}

.element .span {
  font-weight: 700;
}

.element .text-wrapper-6 {
  font-family: Neue Haas Grotesk Text Pro-Regular, Helvetica;
  font-size: 24px;
  line-height: 33.6px;
}

.element .overlap-wrapper {
  background-image: url("rectangle-122.ee52b271.svg");
  background-size: 100% 100%;
  width: 1920px;
  height: 540px;
  position: absolute;
  top: 14196px;
  left: 0;
}

.element .logo-wrapper {
  background-image: url("mask-group-3.5dd0a1a5.png");
  background-size: 100% 100%;
  height: 540px;
  position: relative;
}

.element .logo {
  width: 144px;
  height: 32px;
  position: absolute;
  top: 391px;
  left: 1657px;
}

.element .overlap-2 {
  width: 1926px;
  height: 3332px;
  position: absolute;
  top: 1492px;
  left: 0;
}

.element .overlap-3 {
  width: 642px;
  height: 585px;
  position: absolute;
  top: 96px;
  left: 120px;
}

.element .frame-8 {
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
  display: inline-flex;
  position: absolute;
  top: 0;
  left: 0;
}

.element .frame-9 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 16px;
  display: inline-flex;
  position: relative;
}

.element .text-wrapper-7 {
  color: #8661df;
  letter-spacing: -.39px;
  width: 608px;
  margin-top: -1px;
  font-family: Neue Haas Grotesk Text Pro-75Bold, Helvetica;
  font-size: 20px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.element .text-wrapper-8 {
  color: #2f2f2f;
  letter-spacing: -.39px;
  width: 641.67px;
  font-family: Neue Haas Grotesk Text Pro-65Medium, Helvetica;
  font-size: 32px;
  font-weight: 500;
  line-height: 48px;
  position: relative;
}

.element .text-wrapper-9 {
  color: #2f2f2f;
  letter-spacing: -.39px;
  width: 640px;
  font-family: Neue Haas Grotesk Text Pro-55Roman, Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: 36px;
  position: relative;
}

.element .overlap-4 {
  width: 1926px;
  height: 3332px;
  position: absolute;
  top: 0;
  left: 0;
}

.element .rectangle {
  background-color: #794fdb;
  width: 920px;
  height: 584px;
  position: absolute;
  top: 90px;
  left: 1000px;
}

.element .iphone {
  width: 456px;
  height: 814px;
  position: absolute;
  top: 0;
  left: 1109px;
}

.element .group-2 {
  width: 1926px;
  height: 2523px;
  position: absolute;
  top: 809px;
  left: 0;
}

.element .overlap-5 {
  width: 1920px;
  height: 2523px;
  position: relative;
}

.element .rectangle-2 {
  background-color: #3d286e;
  width: 1920px;
  height: 2314px;
  position: absolute;
  top: 0;
  left: 0;
}

.element .competitive {
  color: #fff;
  letter-spacing: -.39px;
  height: 40px;
  font-family: Neue Haas Grotesk Text Pro-65Medium, Helvetica;
  font-size: 32px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 810px;
  left: 900px;
}

.element .research {
  background-color: #fff;
  border-radius: 16px;
  width: 726px;
  height: 517px;
  position: absolute;
  top: 764px;
  left: 121px;
}

.element .screenshot {
  width: 612px;
  height: 422px;
  position: absolute;
  top: 47px;
  left: 25px;
}

.element .research-2 {
  background-image: url("rectangle-677-1.67e864c0.svg");
  background-size: 100% 100%;
  width: 901px;
  height: 781px;
  position: absolute;
  top: 1022px;
  left: 902px;
}

.element .screenshot-2 {
  width: 848px;
  height: 533px;
  position: absolute;
  top: 134px;
  left: 27px;
}

.element .text-wrapper-10 {
  color: #000;
  letter-spacing: -.39px;
  white-space: nowrap;
  height: 40px;
  font-family: Neue Haas Grotesk Text Pro-65Medium, Helvetica;
  font-size: 32px;
  font-weight: 500;
  line-height: 40px;
  position: absolute;
  top: 61px;
  left: 50px;
}

.element .screenshot-wrapper {
  background-color: #fff;
  border-radius: 16px;
  width: 728px;
  height: 466px;
  position: absolute;
  top: 1337px;
  left: 120px;
}

.element .screenshot-3 {
  width: 665px;
  height: 466px;
  position: absolute;
  top: 0;
  left: 32px;
}

.element .text-wrapper-11 {
  color: #fffcf6;
  letter-spacing: -.39px;
  opacity: .8;
  width: 901px;
  height: 96px;
  font-family: Neue Haas Grotesk Text Pro-65Medium, Helvetica;
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  position: absolute;
  top: 870px;
  left: 900px;
}

.element .text-wrapper-12 {
  color: #fcffeb;
  letter-spacing: -.39px;
  width: 608px;
  height: 25px;
  font-family: Neue Haas Grotesk Text Pro-75Bold, Helvetica;
  font-size: 20px;
  font-weight: 700;
  line-height: normal;
  position: absolute;
  top: 773px;
  left: 900px;
}

.element .frame-10 {
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  display: inline-flex;
  position: absolute;
  top: 152px;
  left: 1191px;
}

.element .frame-11 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 12px;
  display: inline-flex;
  position: relative;
}

.element .text-wrapper-13 {
  color: #fff;
  letter-spacing: -.39px;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-family: Neue Haas Grotesk Text Pro-75Bold, Helvetica;
  font-size: 20px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.element .text-wrapper-14 {
  color: #fff;
  letter-spacing: -.39px;
  width: 608px;
  font-family: Neue Haas Grotesk Text Pro-65Medium, Helvetica;
  font-size: 40px;
  font-weight: 500;
  line-height: normal;
  position: relative;
}

.element .text-wrapper-15 {
  color: #fff;
  letter-spacing: -.39px;
  opacity: .96;
  align-self: stretch;
  font-family: Neue Haas Grotesk Text Pro-55Roman, Helvetica;
  font-size: 32px;
  font-weight: 400;
  line-height: 48px;
  position: relative;
}

.element .user-goals {
  background-color: #fffcf6;
  border-radius: 15px;
  width: 976px;
  height: 603px;
  position: absolute;
  top: 72px;
  left: 120px;
}

.element .group-3 {
  width: 800px;
  height: 498px;
  position: relative;
  top: 52px;
  left: 90px;
}

.element .text-wrapper-16 {
  color: #686868;
  letter-spacing: -.23px;
  height: 40px;
  font-family: Neue Haas Grotesk Text Pro-65Medium, Helvetica;
  font-size: 32px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
}

.element .text-wrapper-17 {
  color: #686868;
  letter-spacing: -.23px;
  height: 22px;
  font-family: Neue Haas Grotesk Text Pro-65Medium, Helvetica;
  font-size: 18px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 52px;
  left: 0;
}

.element .group-4 {
  width: 798px;
  height: 75px;
  position: absolute;
  top: 113px;
  left: 0;
}

.element .text-wrapper-18 {
  color: #686868;
  letter-spacing: -.23px;
  height: 19px;
  font-family: Neue Haas Grotesk Text Pro-65Medium, Helvetica;
  font-size: 15.3px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
}

.element .group-5 {
  width: 798px;
  height: 52px;
  position: absolute;
  top: 23px;
  left: 0;
}

.element .overlap-group-2 {
  background-color: #59346c;
  width: 796px;
  height: 52px;
  position: relative;
}

.element .text-wrapper-19 {
  color: #fff;
  letter-spacing: -.23px;
  height: 25px;
  font-family: Neue Haas Grotesk Text Pro-75Bold, Helvetica;
  font-size: 20px;
  font-weight: 700;
  line-height: normal;
  position: absolute;
  top: 14px;
  left: 746px;
}

.element .group-6 {
  width: 221px;
  height: 79px;
  position: absolute;
  top: 419px;
  left: 0;
}

.element .overlap-6 {
  background-color: #59346c;
  width: 217px;
  height: 56px;
  position: absolute;
  top: 23px;
  left: 0;
}

.element .text-wrapper-20 {
  color: #fff;
  letter-spacing: -.23px;
  height: 25px;
  font-family: Neue Haas Grotesk Text Pro-75Bold, Helvetica;
  font-size: 20px;
  font-weight: 700;
  line-height: normal;
  position: absolute;
  top: 16px;
  left: 169px;
}

.element .group-7 {
  width: 641px;
  height: 80px;
  position: absolute;
  top: 212px;
  left: 0;
}

.element .overlap-7 {
  background-color: #59346c;
  width: 637px;
  height: 56px;
  position: absolute;
  top: 24px;
  left: 0;
}

.element .text-wrapper-21 {
  color: #fff;
  letter-spacing: -.23px;
  height: 25px;
  font-family: Neue Haas Grotesk Text Pro-75Bold, Helvetica;
  font-size: 20px;
  font-weight: 700;
  line-height: normal;
  position: absolute;
  top: 16px;
  left: 577px;
}

.element .group-8 {
  width: 342px;
  height: 80px;
  position: absolute;
  top: 316px;
  left: 0;
}

.element .text-wrapper-22 {
  color: #686868;
  letter-spacing: -.23px;
  height: 19px;
  font-family: Neue Haas Grotesk Text Pro-65Medium, Helvetica;
  font-size: 15.3px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 4px;
}

.element .overlap-8 {
  background-color: #59346c;
  width: 338px;
  height: 56px;
  position: absolute;
  top: 24px;
  left: 0;
}

.element .text-wrapper-23 {
  color: #fff;
  letter-spacing: -.23px;
  width: 40px;
  height: 25px;
  font-family: Neue Haas Grotesk Text Pro-75Bold, Helvetica;
  font-size: 20px;
  font-weight: 700;
  line-height: normal;
  position: absolute;
  top: 16px;
  left: 285px;
}

.element .ecosystem {
  background-image: url("mask-group-2.40ea77a1.png");
  background-size: 100% 100%;
  width: 1680px;
  height: 664px;
  position: absolute;
  top: 1859px;
  left: 120px;
}

.element .text-wrapper-24 {
  color: #5e5e5e;
  letter-spacing: 0;
  font-family: Neue Haas Grotesk Text Pro-75Bold, Helvetica;
  font-size: 32px;
  font-weight: 700;
  line-height: normal;
  position: absolute;
  top: 85px;
  left: 100px;
}

.element .text-wrapper-25 {
  color: #5e5e5e;
  letter-spacing: 0;
  width: 539px;
  font-family: Neue Haas Grotesk Text Pro-55Roman, Helvetica;
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  position: absolute;
  top: 148px;
  left: 100px;
}

.element .research-3 {
  background-image: url("rectangle-677.f1c90628.svg");
  background-size: 100% 100%;
  width: 901px;
  height: 781px;
  position: absolute;
  top: 1022px;
  left: 902px;
}

.element .text-wrapper-26 {
  color: #fffcf6;
  letter-spacing: -.39px;
  opacity: .8;
  width: 901px;
  height: 96px;
  font-family: Neue Haas Grotesk Text Pro-55Roman, Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  position: absolute;
  top: 870px;
  left: 900px;
}

.element .ecosystem-2 {
  background-image: url("mask-group-1.aa00c0ad.png");
  background-size: 100% 100%;
  width: 1680px;
  height: 664px;
  position: absolute;
  top: 1859px;
  left: 120px;
}

.element .text-wrapper-27 {
  color: #5e5e5e;
  letter-spacing: 0;
  font-family: Neue Haas Grotesk Text Pro-75Bold, Helvetica;
  font-size: 32px;
  font-weight: 700;
  line-height: normal;
  position: absolute;
  top: 134px;
  left: 100px;
}

.element .text-wrapper-28 {
  color: #5e5e5e;
  letter-spacing: 0;
  font-family: Neue Haas Grotesk Text Pro-75Bold, Helvetica;
  font-size: 24px;
  font-weight: 700;
  line-height: normal;
  position: absolute;
  top: 86px;
  left: 100px;
}

.element .text-wrapper-29 {
  color: #5e5e5e;
  letter-spacing: 0;
  width: 539px;
  font-family: Neue Haas Grotesk Text Pro-55Roman, Helvetica;
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  position: absolute;
  top: 197px;
  left: 100px;
}

.element .text-wrapper-30 {
  color: #282828;
  letter-spacing: -.12px;
  width: 660px;
  height: 102px;
  font-family: Neue Haas Grotesk Text Pro-65Medium, Helvetica;
  font-size: 24px;
  font-weight: 500;
  line-height: 33.6px;
  position: absolute;
  top: 5048px;
  left: 119px;
}

.element .text-wrapper-31 {
  color: #191919;
  letter-spacing: -.24px;
  height: 50px;
  font-family: Neue Haas Grotesk Text Pro-65Medium, Helvetica;
  font-size: 40px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 4977px;
  left: 119px;
}

.element .text-wrapper-32 {
  color: #862de2;
  letter-spacing: -.24px;
  height: 25px;
  font-family: Neue Haas Grotesk Text Pro-65Medium, Helvetica;
  font-size: 20px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 4944px;
  left: 119px;
}

.element .frame-12 {
  width: 1680px;
  height: 1051px;
  position: absolute;
  top: 5284px;
  left: 120px;
}

.element .rectangle-wrapper {
  background-image: url("c.f409c358.png");
  background-size: 100% 100%;
  width: 835px;
  height: 325px;
  position: absolute;
  top: 4903px;
  left: 965px;
}

.element .rectangle-3 {
  border: .82px solid #00000052;
  height: 324px;
}

.element .frame-13 {
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  display: inline-flex;
  position: absolute;
  top: 13900px;
  left: 147px;
}

.element .text-wrapper-33 {
  color: #000;
  letter-spacing: -.17px;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.75px;
  font-family: Neue Haas Grotesk Text Pro-75Bold, Helvetica;
  font-size: 32px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.element .text-wrapper-34 {
  color: #000;
  letter-spacing: -.24px;
  width: 1507.98px;
  font-family: Neue Haas Grotesk Text Pro-55Roman, Helvetica;
  font-size: 32px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.element .mockup-wrapper {
  background-image: url("rectangle-7.8baab304.svg");
  background-size: 100% 100%;
  border-radius: 16px;
  width: 524px;
  height: 713px;
  position: absolute;
  top: 6391px;
  left: 120px;
  overflow: hidden;
}

.element .mockup {
  width: 484px;
  height: 968px;
  position: relative;
  top: 90px;
  left: 101px;
  box-shadow: 0 53.86px 62.84px #4f4f4f5e;
}

.element .overlap-9 {
  height: 968px;
  position: relative;
}

.element .body-space-gray {
  width: 484px;
  height: 968px;
  position: absolute;
  top: 0;
  left: 0;
}

.element .overlap-10 {
  width: 423px;
  height: 623px;
  position: absolute;
  top: 0;
  left: 0;
}

.element .button-space-gray {
  width: 4px;
  height: 38px;
  position: absolute;
  top: 130px;
  left: 0;
  overflow: hidden;
}

.element .overlap-group-3 {
  height: 38px;
  position: relative;
}

.element .rectangle-4 {
  background: linear-gradient(#fff0 0%, #fff3 100%);
  width: 1px;
  height: 35px;
  position: absolute;
  top: 2px;
  left: 0;
  box-shadow: inset -.56px 0 .56px #00000026;
}

.element .union {
  width: 4px;
  height: 38px;
  position: absolute;
  top: 0;
  left: 0;
}

.element .button-space-gray-2 {
  width: 4px;
  height: 68px;
  position: absolute;
  top: 200px;
  left: 0;
}

.element .overlap-11 {
  height: 68px;
  position: relative;
}

.element .rectangle-5 {
  background: linear-gradient(#fff0 0%, #fff3 100%);
  width: 1px;
  height: 65px;
  position: absolute;
  top: 2px;
  left: 0;
  box-shadow: inset -.56px 0 .56px #00000026;
}

.element .union-2 {
  width: 4px;
  height: 68px;
  position: absolute;
  top: 0;
  left: 0;
}

.element .button-space-gray-3 {
  width: 4px;
  height: 68px;
  position: absolute;
  top: 290px;
  left: 0;
}

.element .img-2 {
  width: 420px;
  height: 623px;
  position: absolute;
  top: 0;
  left: 3px;
}

.element .outer-ring {
  width: 419px;
  height: 622px;
  position: absolute;
  top: 1px;
  left: 4px;
}

.element .bands {
  width: 420px;
  height: 531px;
  position: absolute;
  top: 92px;
  left: 3px;
}

.element .button-space-gray-4 {
  width: 4px;
  height: 111px;
  position: absolute;
  top: 223px;
  left: 479px;
  overflow: hidden;
  transform: rotate(180deg);
}

.element .rectangle-6 {
  background: linear-gradient(#fff0 0%, #fff3 100%);
  width: 1px;
  height: 108px;
  position: absolute;
  top: 2px;
  left: 0;
  box-shadow: inset -.56px 0 .56px #00000026;
}

.element .union-3 {
  width: 4px;
  height: 111px;
  position: absolute;
  top: -5217px;
  left: -215px;
  transform: rotate(-180deg);
}

.element .base {
  width: 409px;
  height: 612px;
  position: absolute;
  top: 11px;
  left: 14px;
}

.element .display {
  width: 454px;
  height: 946px;
  position: absolute;
  top: 11px;
  left: 15px;
}

.element .mask-group {
  width: 391px;
  height: 595px;
  position: absolute;
  top: 17px;
  left: 17px;
}

.element .frame-14 {
  width: 524px;
  height: 713px;
  position: absolute;
  top: 6391px;
  left: 1277px;
}

.element .holding-hand {
  width: 573px;
  height: 713px;
  position: absolute;
  top: 6391px;
  left: 674px;
}

.element .img-3 {
  width: 1684px;
  height: 947px;
  position: absolute;
  top: 7153px;
  left: 117px;
}

/*# sourceMappingURL=index.a2439a6e.css.map */
