:root {
  --brand-tonesprimary-voilaprimary40: rgba(121, 79, 219, 1);
  --brand-tonesprimary-voilaprimary50: rgba(134, 97, 223, 1);
  --brand-tonesprimary-voilaprimary90: rgba(215, 202, 244, 1);
  --brand-tonesprimary-voilaprimary95: rgba(228, 220, 248, 1);
  --brand-tonesprimary-voilaprimary99: rgba(249, 247, 253, 1);
  --brand-tonessecondary-dawnsecondary90: rgba(172, 153, 181, 1);
  --brand-tonessecondary-dawnsecondary95: rgba(213, 204, 218, 1);
  --CTA-bold-font-family: "DMSans-Bold", Helvetica;
  --CTA-bold-font-size: 16px;
  --CTA-bold-font-style: normal;
  --CTA-bold-font-weight: 700;
  --CTA-bold-letter-spacing: -0.30000001192092896px;
  --CTA-bold-line-height: 24px;
  --light-00: 0px 5px 6px 1px rgba(189, 189, 189, 0.15), 0px 2px 4px 0px rgba(189, 189, 189, 0.2);
  --light-02: 0px 6px 6px 0px rgba(209, 209, 209, 0.3), 0px 9px 8px 3px rgba(184, 184, 184, 0.15);
  --light-03: 0px 2px 3px 0px rgba(182, 182, 182, 0.3), 0px 6px 10px 4px rgba(182, 182, 182, 0.15);
  --prm-12: rgba(121, 79, 219, 0.12);
  --prm-16: rgba(121, 79, 219, 0.16);
  --prm-8: rgba(121, 79, 219, 0.08);
  --scd-16: rgba(48, 32, 88, 0.16);
  --ui-essentialsneutralntrl100: rgba(255, 255, 255, 1);
  --ui-essentialsneutralntrl40: rgba(106, 104, 107, 1);
  --ui-essentialsneutralntrl60: rgba(155, 154, 156, 1);
  --ui-essentialsneutralntrl90: rgba(230, 230, 230, 1);
  --ui-essentialsneutralntrl95: rgba(242, 242, 242, 1);
}
