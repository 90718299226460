.element {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

body{
  overflow-x: hidden;
}
.element .div {
  background-color: #ffffff;
  height: 14736px;
  /* overflow: hidden; */
  position: relative;
  width: 1920px;
}

.element .image{
  position: absolute;
  left: 0;
  top: 8100px;
  width: 1920px;
  height: 5753px;
}
.element .overlap {
  height: 966px;
  left: 0;
  position: absolute;
  top: 4px;
  width: 1920px;
}

.element .hero {
  background-image: url(../../../static/img/rectangle-95.png);
  background-size: 100% 100%;
  height: 966px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1920px;
}

.element .group {
  height: 120px;
  left: 120px;
  position: relative;
  top: 517px;
  width: 575px;
}

.element .frame {
  align-items: flex-start;
  display: inline-flex;
  gap: 16px;
  left: 0;
  position: absolute;
  top: 64px;
}

.element .UIX {
  height: 56px;
  position: relative;
  width: 209px;
}

.element .frame-wrapper {
  align-items: flex-start;
  background-color: #ffffff;
  border-radius: 28px;
  display: inline-flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  padding: 4px 16px 4px 4px;
  position: relative;
}

.element .frame-2 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 12px;
  position: relative;
}

.element .overlap-group-wrapper {
  background-color: #2f2f2f;
  border-radius: 24px;
  height: 48px;
  position: relative;
  width: 48px;
}

.element .overlap-group {
  height: 22px;
  left: 13px;
  position: relative;
  top: 13px;
  width: 22px;
}

.element .line {
  height: 22px;
  left: 9px;
  position: absolute;
  top: 0;
  width: 5px;
}

.element .img {
  height: 4px;
  left: 0;
  position: absolute;
  top: 9px;
  width: 22px;
}

.element .text-wrapper {
  color: #191919;
  font-family: "Neue Haas Grotesk Display Pro-75Bold", Helvetica;
  font-size: 24px;
  font-weight: 700;
  letter-spacing: 0.46px;
  line-height: 32.9px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.element .div-wrapper {
  height: 56px;
  position: relative;
  width: 149px;
}

.element .UIX-2 {
  height: 56px;
  position: relative;
  width: 183px;
}

.element .text-wrapper-2 {
  color: #dadada;
  font-family: "Neue Haas Grotesk Text Pro-75Bold", Helvetica;
  font-size: 24px;
  font-weight: 700;
  height: 40px;
  left: 0;
  letter-spacing: -0.39px;
  line-height: 40px;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.element .frame-3 {
  align-items: flex-end;
  display: inline-flex;
  flex-direction: column;
  gap: 24px;
  left: 1620px;
  position: absolute;
  top: 116px;
}

.element .android-IOS {
  color: #ffffff;
  font-family: "Neue Haas Grotesk Text Pro-55Roman", Helvetica;
  font-size: 28px;
  font-weight: 400;
  letter-spacing: -0.39px;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  text-align: right;
  width: fit-content;
}

.element .text-wrapper-3 {
  color: #ffffff;
  font-family: "Neue Haas Grotesk Text Pro-55Roman", Helvetica;
  font-size: 28px;
  font-weight: 400;
  letter-spacing: -0.39px;
  line-height: normal;
  position: relative;
  width: fit-content;
}

.element .frame-4 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 24px;
  justify-content: center;
  left: 120px;
  position: absolute;
  top: 116px;
}

.element .text-wrapper-4 {
  color: #ffffff;
  font-family: "Neue Haas Grotesk Text Pro-75Bold", Helvetica;
  font-size: 32px;
  font-weight: 700;
  letter-spacing: -0.16px;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.element .p {
  color: #ffffff;
  font-family: "Neue Haas Grotesk Text Pro-55Roman", Helvetica;
  font-size: 80px;
  font-weight: 400;
  letter-spacing: -0.4px;
  line-height: 96px;
  position: relative;
  width: 565px;
}

.element .the-challenge {
  align-items: center;
  background-color: #060308;
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: center;
  left: 0;
  padding: 64px 0px;
  position: absolute;
  top: 970px;
  width: 1920px;
}

.element .frame-5 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 48px;
  justify-content: center;
  padding: 0px 120px;
  position: relative;
  width: 100%;
}

.element .frame-6 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.element .frame-7 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 32px;
  justify-content: center;
  position: relative;
}

.element .text-wrapper-5 {
  color: #ffffff;
  font-family: "Neue Haas Grotesk Text Pro-75Bold", Helvetica;
  font-size: 32px;
  font-weight: 700;
  letter-spacing: -0.17px;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.element .finding-love-in-a {
  color: #ffffff;
  font-family: "Neue Haas Grotesk Text Pro-Bold", Helvetica;
  font-size: 32px;
  font-weight: 400;
  letter-spacing: -0.24px;
  line-height: 44.8px;
  margin-top: -1px;
  position: relative;
  width: 1275px;
}

.element .span {
  font-weight: 700;
}

.element .text-wrapper-6 {
  font-family: "Neue Haas Grotesk Text Pro-Regular", Helvetica;
  font-size: 24px;
  line-height: 33.6px;
}

.element .overlap-wrapper {
  background-image: url(../../../static/img/rectangle-122.svg);
  background-size: 100% 100%;
  height: 540px;
  left: 0;
  position: absolute;
  top: 14196px;
  width: 1920px;
}

.element .logo-wrapper {
  background-image: url(../../../static/img/mask-group-3.png);
  background-size: 100% 100%;
  height: 540px;
  position: relative;
}

.element .logo {
  height: 32px;
  left: 1657px;
  position: absolute;
  top: 391px;
  width: 144px;
}

.element .overlap-2 {
  height: 3332px;
  left: 0;
  position: absolute;
  top: 1492px;
  width: 1926px;
}

.element .overlap-3 {
  height: 585px;
  left: 120px;
  position: absolute;
  top: 96px;
  width: 642px;
}

.element .frame-8 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 40px;
  left: 0;
  position: absolute;
  top: 0;
}

.element .frame-9 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 16px;
  position: relative;
}

.element .text-wrapper-7 {
  color: #8661df;
  font-family: "Neue Haas Grotesk Text Pro-75Bold", Helvetica;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: -0.39px;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: 608px;
}

.element .text-wrapper-8 {
  color: #2f2f2f;
  font-family: "Neue Haas Grotesk Text Pro-65Medium", Helvetica;
  font-size: 32px;
  font-weight: 500;
  letter-spacing: -0.39px;
  line-height: 48px;
  position: relative;
  width: 641.67px;
}

.element .text-wrapper-9 {
  color: #2f2f2f;
  font-family: "Neue Haas Grotesk Text Pro-55Roman", Helvetica;
  font-size: 24px;
  font-weight: 400;
  letter-spacing: -0.39px;
  line-height: 36px;
  position: relative;
  width: 640px;
}

.element .overlap-4 {
  height: 3332px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1926px;
}

.element .rectangle {
  background-color: #794fdb;
  height: 584px;
  left: 1000px;
  position: absolute;
  top: 90px;
  width: 920px;
}

.element .iphone {
  height: 814px;
  left: 1109px;
  position: absolute;
  top: 0;
  width: 456px;
}

.element .group-2 {
  height: 2523px;
  left: 0;
  position: absolute;
  top: 809px;
  width: 1926px;
}

.element .overlap-5 {
  height: 2523px;
  position: relative;
  width: 1920px;
}

.element .rectangle-2 {
  background-color: #3d286e;
  height: 2314px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1920px;
}

.element .competitive {
  color: #ffffff;
  font-family: "Neue Haas Grotesk Text Pro-65Medium", Helvetica;
  font-size: 32px;
  font-weight: 500;
  height: 40px;
  left: 900px;
  letter-spacing: -0.39px;
  line-height: normal;
  position: absolute;
  top: 810px;
}

.element .research {
  background-color: #ffffff;
  border-radius: 16px;
  height: 517px;
  left: 121px;
  position: absolute;
  top: 764px;
  width: 726px;
}

.element .screenshot {
  height: 422px;
  left: 25px;
  position: absolute;
  top: 47px;
  width: 612px;
}

.element .research-2 {
  background-image: url(../../../static/img/rectangle-677-1.svg);
  background-size: 100% 100%;
  height: 781px;
  left: 902px;
  position: absolute;
  top: 1022px;
  width: 901px;
}

.element .screenshot-2 {
  height: 533px;
  left: 27px;
  position: absolute;
  top: 134px;
  width: 848px;
}

.element .text-wrapper-10 {
  color: #000000;
  font-family: "Neue Haas Grotesk Text Pro-65Medium", Helvetica;
  font-size: 32px;
  font-weight: 500;
  height: 40px;
  left: 50px;
  letter-spacing: -0.39px;
  line-height: 40px;
  position: absolute;
  top: 61px;
  white-space: nowrap;
}

.element .screenshot-wrapper {
  background-color: #ffffff;
  border-radius: 16px;
  height: 466px;
  left: 120px;
  position: absolute;
  top: 1337px;
  width: 728px;
}

.element .screenshot-3 {
  height: 466px;
  left: 32px;
  position: absolute;
  top: 0;
  width: 665px;
}

.element .text-wrapper-11 {
  color: #fffcf6;
  font-family: "Neue Haas Grotesk Text Pro-65Medium", Helvetica;
  font-size: 24px;
  font-weight: 500;
  height: 96px;
  left: 900px;
  letter-spacing: -0.39px;
  line-height: 32px;
  opacity: 0.8;
  position: absolute;
  top: 870px;
  width: 901px;
}

.element .text-wrapper-12 {
  color: #fcffeb;
  font-family: "Neue Haas Grotesk Text Pro-75Bold", Helvetica;
  font-size: 20px;
  font-weight: 700;
  height: 25px;
  left: 900px;
  letter-spacing: -0.39px;
  line-height: normal;
  position: absolute;
  top: 773px;
  width: 608px;
}

.element .frame-10 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 20px;
  left: 1191px;
  position: absolute;
  top: 152px;
}

.element .frame-11 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 12px;
  position: relative;
}

.element .text-wrapper-13 {
  color: #ffffff;
  font-family: "Neue Haas Grotesk Text Pro-75Bold", Helvetica;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: -0.39px;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.element .text-wrapper-14 {
  color: #ffffff;
  font-family: "Neue Haas Grotesk Text Pro-65Medium", Helvetica;
  font-size: 40px;
  font-weight: 500;
  letter-spacing: -0.39px;
  line-height: normal;
  position: relative;
  width: 608px;
}

.element .text-wrapper-15 {
  align-self: stretch;
  color: #ffffff;
  font-family: "Neue Haas Grotesk Text Pro-55Roman", Helvetica;
  font-size: 32px;
  font-weight: 400;
  letter-spacing: -0.39px;
  line-height: 48px;
  opacity: 0.96;
  position: relative;
}

.element .user-goals {
  background-color: #fffcf6;
  border-radius: 15px;
  height: 603px;
  left: 120px;
  position: absolute;
  top: 72px;
  width: 976px;
}

.element .group-3 {
  height: 498px;
  left: 90px;
  position: relative;
  top: 52px;
  width: 800px;
}

.element .text-wrapper-16 {
  color: #686868;
  font-family: "Neue Haas Grotesk Text Pro-65Medium", Helvetica;
  font-size: 32px;
  font-weight: 500;
  height: 40px;
  left: 0;
  letter-spacing: -0.23px;
  line-height: normal;
  position: absolute;
  top: 0;
}

.element .text-wrapper-17 {
  color: #686868;
  font-family: "Neue Haas Grotesk Text Pro-65Medium", Helvetica;
  font-size: 18px;
  font-weight: 500;
  height: 22px;
  left: 0;
  letter-spacing: -0.23px;
  line-height: normal;
  position: absolute;
  top: 52px;
}

.element .group-4 {
  height: 75px;
  left: 0;
  position: absolute;
  top: 113px;
  width: 798px;
}

.element .text-wrapper-18 {
  color: #686868;
  font-family: "Neue Haas Grotesk Text Pro-65Medium", Helvetica;
  font-size: 15.3px;
  font-weight: 500;
  height: 19px;
  left: 0;
  letter-spacing: -0.23px;
  line-height: normal;
  position: absolute;
  top: 0;
}

.element .group-5 {
  height: 52px;
  left: 0;
  position: absolute;
  top: 23px;
  width: 798px;
}

.element .overlap-group-2 {
  background-color: #59346c;
  height: 52px;
  position: relative;
  width: 796px;
}

.element .text-wrapper-19 {
  color: #ffffff;
  font-family: "Neue Haas Grotesk Text Pro-75Bold", Helvetica;
  font-size: 20px;
  font-weight: 700;
  height: 25px;
  left: 746px;
  letter-spacing: -0.23px;
  line-height: normal;
  position: absolute;
  top: 14px;
}

.element .group-6 {
  height: 79px;
  left: 0;
  position: absolute;
  top: 419px;
  width: 221px;
}

.element .overlap-6 {
  background-color: #59346c;
  height: 56px;
  left: 0;
  position: absolute;
  top: 23px;
  width: 217px;
}

.element .text-wrapper-20 {
  color: #ffffff;
  font-family: "Neue Haas Grotesk Text Pro-75Bold", Helvetica;
  font-size: 20px;
  font-weight: 700;
  height: 25px;
  left: 169px;
  letter-spacing: -0.23px;
  line-height: normal;
  position: absolute;
  top: 16px;
}

.element .group-7 {
  height: 80px;
  left: 0;
  position: absolute;
  top: 212px;
  width: 641px;
}

.element .overlap-7 {
  background-color: #59346c;
  height: 56px;
  left: 0;
  position: absolute;
  top: 24px;
  width: 637px;
}

.element .text-wrapper-21 {
  color: #ffffff;
  font-family: "Neue Haas Grotesk Text Pro-75Bold", Helvetica;
  font-size: 20px;
  font-weight: 700;
  height: 25px;
  left: 577px;
  letter-spacing: -0.23px;
  line-height: normal;
  position: absolute;
  top: 16px;
}

.element .group-8 {
  height: 80px;
  left: 0;
  position: absolute;
  top: 316px;
  width: 342px;
}

.element .text-wrapper-22 {
  color: #686868;
  font-family: "Neue Haas Grotesk Text Pro-65Medium", Helvetica;
  font-size: 15.3px;
  font-weight: 500;
  height: 19px;
  left: 4px;
  letter-spacing: -0.23px;
  line-height: normal;
  position: absolute;
  top: 0;
}

.element .overlap-8 {
  background-color: #59346c;
  height: 56px;
  left: 0;
  position: absolute;
  top: 24px;
  width: 338px;
}

.element .text-wrapper-23 {
  color: #ffffff;
  font-family: "Neue Haas Grotesk Text Pro-75Bold", Helvetica;
  font-size: 20px;
  font-weight: 700;
  height: 25px;
  left: 285px;
  letter-spacing: -0.23px;
  line-height: normal;
  position: absolute;
  top: 16px;
  width: 40px;
}

.element .ecosystem {
  background-image: url(../../../static/img/mask-group-2.png);
  background-size: 100% 100%;
  height: 664px;
  left: 120px;
  position: absolute;
  top: 1859px;
  width: 1680px;
}

.element .text-wrapper-24 {
  color: #5e5e5e;
  font-family: "Neue Haas Grotesk Text Pro-75Bold", Helvetica;
  font-size: 32px;
  font-weight: 700;
  left: 100px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 85px;
}

.element .text-wrapper-25 {
  color: #5e5e5e;
  font-family: "Neue Haas Grotesk Text Pro-55Roman", Helvetica;
  font-size: 18px;
  font-weight: 400;
  left: 100px;
  letter-spacing: 0;
  line-height: 28px;
  position: absolute;
  top: 148px;
  width: 539px;
}

.element .research-3 {
  background-image: url(../../../static/img/rectangle-677.svg);
  background-size: 100% 100%;
  height: 781px;
  left: 902px;
  position: absolute;
  top: 1022px;
  width: 901px;
}

.element .text-wrapper-26 {
  color: #fffcf6;
  font-family: "Neue Haas Grotesk Text Pro-55Roman", Helvetica;
  font-size: 24px;
  font-weight: 400;
  height: 96px;
  left: 900px;
  letter-spacing: -0.39px;
  line-height: 32px;
  opacity: 0.8;
  position: absolute;
  top: 870px;
  width: 901px;
}

.element .ecosystem-2 {
  background-image: url(../../../static/img/mask-group-1.png);
  background-size: 100% 100%;
  height: 664px;
  left: 120px;
  position: absolute;
  top: 1859px;
  width: 1680px;
}

.element .text-wrapper-27 {
  color: #5e5e5e;
  font-family: "Neue Haas Grotesk Text Pro-75Bold", Helvetica;
  font-size: 32px;
  font-weight: 700;
  left: 100px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 134px;
}

.element .text-wrapper-28 {
  color: #5e5e5e;
  font-family: "Neue Haas Grotesk Text Pro-75Bold", Helvetica;
  font-size: 24px;
  font-weight: 700;
  left: 100px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 86px;
}

.element .text-wrapper-29 {
  color: #5e5e5e;
  font-family: "Neue Haas Grotesk Text Pro-55Roman", Helvetica;
  font-size: 18px;
  font-weight: 400;
  left: 100px;
  letter-spacing: 0;
  line-height: 28px;
  position: absolute;
  top: 197px;
  width: 539px;
}

.element .text-wrapper-30 {
  color: #282828;
  font-family: "Neue Haas Grotesk Text Pro-65Medium", Helvetica;
  font-size: 24px;
  font-weight: 500;
  height: 102px;
  left: 119px;
  letter-spacing: -0.12px;
  line-height: 33.6px;
  position: absolute;
  top: 5048px;
  width: 660px;
}

.element .text-wrapper-31 {
  color: #191919;
  font-family: "Neue Haas Grotesk Text Pro-65Medium", Helvetica;
  font-size: 40px;
  font-weight: 500;
  height: 50px;
  left: 119px;
  letter-spacing: -0.24px;
  line-height: normal;
  position: absolute;
  top: 4977px;
}

.element .text-wrapper-32 {
  color: #862de2;
  font-family: "Neue Haas Grotesk Text Pro-65Medium", Helvetica;
  font-size: 20px;
  font-weight: 500;
  height: 25px;
  left: 119px;
  letter-spacing: -0.24px;
  line-height: normal;
  position: absolute;
  top: 4944px;
}

.element .frame-12 {
  height: 1051px;
  left: 120px;
  position: absolute;
  top: 5284px;
  width: 1680px;
}

.element .rectangle-wrapper {
  background-image: url(../../../static/img/c.png);
  background-size: 100% 100%;
  height: 325px;
  left: 965px;
  position: absolute;
  top: 4903px;
  width: 835px;
}

.element .rectangle-3 {
  border: 0.82px solid;
  border-color: #00000052;
  height: 324px;
}

.element .frame-13 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 32px;
  left: 147px;
  position: absolute;
  top: 13900px;
}

.element .text-wrapper-33 {
  color: #000000;
  font-family: "Neue Haas Grotesk Text Pro-75Bold", Helvetica;
  font-size: 32px;
  font-weight: 700;
  letter-spacing: -0.17px;
  line-height: normal;
  margin-top: -0.75px;
  position: relative;
  width: fit-content;
}

.element .text-wrapper-34 {
  color: #000000;
  font-family: "Neue Haas Grotesk Text Pro-55Roman", Helvetica;
  font-size: 32px;
  font-weight: 400;
  letter-spacing: -0.24px;
  line-height: normal;
  position: relative;
  width: 1507.98px;
}

.element .mockup-wrapper {
  background-image: url(../../../static/img/rectangle-7.svg);
  background-size: 100% 100%;
  border-radius: 16px;
  height: 713px;
  left: 120px;
  overflow: hidden;
  position: absolute;
  top: 6391px;
  width: 524px;
}

.element .mockup {
  box-shadow: 0px 53.86px 62.84px #4f4f4f5e;
  height: 968px;
  left: 101px;
  position: relative;
  top: 90px;
  width: 484px;
}

.element .overlap-9 {
  height: 968px;
  position: relative;
}

.element .body-space-gray {
  height: 968px;
  left: 0;
  position: absolute;
  top: 0;
  width: 484px;
}

.element .overlap-10 {
  height: 623px;
  left: 0;
  position: absolute;
  top: 0;
  width: 423px;
}

.element .button-space-gray {
  height: 38px;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 130px;
  width: 4px;
}

.element .overlap-group-3 {
  height: 38px;
  position: relative;
}

.element .rectangle-4 {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.2) 100%);
  box-shadow: inset -0.56px 0px 0.56px #00000026;
  height: 35px;
  left: 0;
  position: absolute;
  top: 2px;
  width: 1px;
}

.element .union {
  height: 38px;
  left: 0;
  position: absolute;
  top: 0;
  width: 4px;
}

.element .button-space-gray-2 {
  height: 68px;
  left: 0;
  position: absolute;
  top: 200px;
  width: 4px;
}

.element .overlap-11 {
  height: 68px;
  position: relative;
}

.element .rectangle-5 {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.2) 100%);
  box-shadow: inset -0.56px 0px 0.56px #00000026;
  height: 65px;
  left: 0;
  position: absolute;
  top: 2px;
  width: 1px;
}

.element .union-2 {
  height: 68px;
  left: 0;
  position: absolute;
  top: 0;
  width: 4px;
}

.element .button-space-gray-3 {
  height: 68px;
  left: 0;
  position: absolute;
  top: 290px;
  width: 4px;
}

.element .img-2 {
  height: 623px;
  left: 3px;
  position: absolute;
  top: 0;
  width: 420px;
}

.element .outer-ring {
  height: 622px;
  left: 4px;
  position: absolute;
  top: 1px;
  width: 419px;
}

.element .bands {
  height: 531px;
  left: 3px;
  position: absolute;
  top: 92px;
  width: 420px;
}

.element .button-space-gray-4 {
  height: 111px;
  left: 479px;
  overflow: hidden;
  position: absolute;
  top: 223px;
  transform: rotate(180deg);
  width: 4px;
}

.element .rectangle-6 {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.2) 100%);
  box-shadow: inset -0.56px 0px 0.56px #00000026;
  height: 108px;
  left: 0;
  position: absolute;
  top: 2px;
  width: 1px;
}

.element .union-3 {
  height: 111px;
  left: -215px;
  position: absolute;
  top: -5217px;
  transform: rotate(-180deg);
  width: 4px;
}

.element .base {
  height: 612px;
  left: 14px;
  position: absolute;
  top: 11px;
  width: 409px;
}

.element .display {
  height: 946px;
  left: 15px;
  position: absolute;
  top: 11px;
  width: 454px;
}

.element .mask-group {
  height: 595px;
  left: 17px;
  position: absolute;
  top: 17px;
  width: 391px;
}

.element .frame-14 {
  height: 713px;
  left: 1277px;
  position: absolute;
  top: 6391px;
  width: 524px;
}

.element .holding-hand {
  height: 713px;
  left: 674px;
  position: absolute;
  top: 6391px;
  width: 573px;
}

.element .img-3 {
  height: 947px;
  left: 117px;
  position: absolute;
  top: 7153px;
  width: 1684px;
}
